import { Chip, ChipProps } from '@mui/material';
import classNames from 'classnames';
import { JSX } from 'react';

export type RSChipProps = ChipProps;

/**
 * This component renders a RSChip component. It is based on the MUI Chip component.
 * The API is the same as the MUI Chip component: https://mui.com/material-ui/api/chip/
 * Only commonly used properties are listed below.
 *
 * @param {string} [color=default] The color of the chip. RSChip supports 'default', 'warning', 'error' and 'success'.
 * @param {React.ReactElement} [icon] The icon element.
 * @param {React.ReactNode} [label] The content of the component.
 * @returns {React.JSX.Element} A React element that renders a RSChip component.
 */
export const RSChip = (props: RSChipProps): JSX.Element => {
  const chipClassNames = classNames('rs-chip', props.className);
  return (
    <Chip
      {...props}
      icon={<div className="rs-chip__icon-container">{props.icon}</div>}
      variant="outlined"
      className={chipClassNames}
    />
  );
};
