import { gql } from '../../../__generated__/gql';

export const MUTATION_COLLECT_DEVICE_SOFTWARE_VERSION = gql(`
  mutation collectDeviceSoftwareConfiguration($deviceIds: [String!]) {
    collectDeviceSoftwareConfiguration(deviceIds: $deviceIds) {
      device {
        id
        deviceSoftwareConfigurationActive {
          id
          rocosComposedVersion
          balenaOsVariant
          balenaOsVersion
          balenaReleaseCommit
          balenaSupervisorVersion
          releaseTagGitDescribe
          updatedAt
        }
      }
    }
  }
`);
