import { ApolloQueryResult, useQuery } from '@apollo/client';
import { Skeleton } from '@mui/material';
import { capitalize, startCase } from 'lodash';
import { Dispatch, JSX, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertConfigurationDrawerMode } from './alert-configuration-drawer-mode';
import { AlertConfigurationForm } from './alert-configuration-form/alert-configuration-form';
import { generateAlertConfigurationCreateDefaultValues } from './alert-configuration-form/alert-configuration-schema';
import { GetAdminCompanyDetailsQuery, RocAlertStatusType, SelectionType } from '../../../../__generated__/graphql';
import { useAuthCheckerWithSubjectInfo } from '../../../../services/authz-checker/authz-checker.hooks';
import { GET_ROC_ALERT_CONFIGURATION } from '../../../../services/queries/admin/companies/get-roc-alert-configuration';
import { CompanyType } from '../../../../types/company-type';
import { RocAlertChannelType } from '../../../../types/roc-alert-configuration';
import { contextualizeBoolean } from '../../../../utilities/contextualize-boolean/contextualize-boolean';
import { getNonNullishDisplayValue } from '../../../../utilities/get-non-nullish-display-value/get-non-nullish-display-value';
import { RSDrawer, RSDrawerProps } from '../../../3-sections/rs-drawer/rs-drawer';
import { EditButton } from '../../../4-features/edit-button/edit-button';
import { ModalDrawerHeader } from '../../../4-features/modal-drawer-header/modal-drawer-header';
import { InformationBlock } from '../../../5-elements/information-block/information-block';
import { Loading } from '../../../5-elements/loading/loading';
import { ErrorPage } from '../../error-page/error-page';

interface AlertConfigurationDrawerProps extends Omit<RSDrawerProps, 'children'> {
  id: string;
  companyId: string;
  companyType: CompanyType;
  drawerMode: AlertConfigurationDrawerMode;
  setAlertConfigurationId: Dispatch<SetStateAction<string>>;
  setDrawerMode: Dispatch<SetStateAction<AlertConfigurationDrawerMode>>;
  refetchCompany: () => Promise<ApolloQueryResult<GetAdminCompanyDetailsQuery>>;
}

export const AlertConfigurationDrawer = ({
  id,
  companyId,
  companyType,
  drawerMode,
  setAlertConfigurationId,
  setDrawerMode,
  refetchCompany,
  ...props
}: AlertConfigurationDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const { result: canUserModifyRocAlertConfiguration, loading: loadingCanUserModifyRocAlertConfiguration } =
    useAuthCheckerWithSubjectInfo({
      action: 'UPDATE',
      subjectInfo: { type: 'RocAlertConfiguration', companyId: companyId! },
      skip: false
    });
  const { loading, data, error } = useQuery(GET_ROC_ALERT_CONFIGURATION, {
    fetchPolicy: 'network-only',
    variables: { id },
    skip: props.open === false || drawerMode === 'create' || id === '' || id === 'new'
  });

  if (drawerMode === 'create') {
    return (
      <RSDrawer {...props} className="alert-configuration-drawer">
        <ModalDrawerHeader
          title={t('companyAdminDetailsPage.alertConfigurationDrawer.addTitle')}
          handleClose={() => setAlertConfigurationId('')}
          iconButtonProps={{ disabled: true }}
        />
        <AlertConfigurationForm
          drawerMode={drawerMode}
          setDrawerMode={setDrawerMode}
          companyId={companyId}
          companyType={companyType}
          setAlertConfigurationId={setAlertConfigurationId}
          refetchCompany={refetchCompany}
          defaultValues={generateAlertConfigurationCreateDefaultValues(companyId)}
        />
      </RSDrawer>
    );
  }

  if (error) {
    const isUUIDError = error.name === 'ApolloError' && error.message.includes('invalid input syntax for type uuid');
    return (
      <RSDrawer {...props} className="alert-configuration-drawer">
        <ModalDrawerHeader handleClose={() => setAlertConfigurationId('')} />
        <div className="alert-configuration-drawer__view" data-testid="alert-configuration-drawer-error-view">
          {isUUIDError ? (
            <ErrorPage
              titleEmphasized={t('pageNotFoundErrorPage.errorCode')}
              title={t('operationsPage.operationDetails.notFound.errorTitle')}
              message={t('pageNotFoundErrorPage.notFoundInfo')}
              hideButtonLink={true}
            />
          ) : (
            <ErrorPage
              titleEmphasized={t('apolloErrorPage.errorCode')}
              title={t('apolloErrorPage.errorTitle')}
              message={error.message}
              hideButtonLink={true}
            />
          )}
        </div>
      </RSDrawer>
    );
  }

  if (loading || loadingCanUserModifyRocAlertConfiguration) {
    return (
      <RSDrawer {...props} className="alert-configuration-drawer">
        <ModalDrawerHeader
          title={<Skeleton variant="text" className="alert-configuration-drawer__title-loading-placeholder" />}
        />
        <div className="alert-configuration-drawer__loading" data-testid="alert-configuration-drawer-loading">
          <Loading />
        </div>
      </RSDrawer>
    );
  }

  const alertConfiguration = data?.rocAlertConfigurationByPk;
  if (!alertConfiguration) {
    return (
      <RSDrawer {...props} className="alert-configuration-drawer">
        <ModalDrawerHeader handleClose={() => setAlertConfigurationId('')} />
        {props.open && (
          <div className="alert-configuration-drawer__error" data-testid="alert-configuration-drawer-error-view">
            <ErrorPage
              titleEmphasized={t('pageNotFoundErrorPage.errorCode')}
              title={t('companyAdminDetailsPage.alertConfigurationDrawer.itemNotFound')}
              message={t('pageNotFoundErrorPage.notFoundInfo')}
              hideButtonLink={true}
            />
          </div>
        )}
      </RSDrawer>
    );
  }

  return (
    <RSDrawer {...props} className="alert-configuration-drawer">
      <ModalDrawerHeader
        title={alertConfiguration.name}
        actions={
          <EditButton
            data-testid="alert-configuration-drawer-edit-button"
            disabled={loadingCanUserModifyRocAlertConfiguration || !canUserModifyRocAlertConfiguration}
            onClick={() => {
              setAlertConfigurationId(id);
              setDrawerMode('edit');
            }}
          />
        }
        handleClose={() => setAlertConfigurationId('')}
        iconButtonProps={{ disabled: drawerMode !== 'view' }}
      />
      {/* The case of "create" has been handled (returned) earlier, therefore here it only handles the "edit" case */}
      {drawerMode === 'edit' ? (
        <AlertConfigurationForm
          drawerMode={drawerMode}
          setDrawerMode={setDrawerMode}
          companyId={companyId}
          companyType={companyType}
          setAlertConfigurationId={setAlertConfigurationId}
          refetchCompany={refetchCompany}
          defaultValues={{
            id: alertConfiguration.id,
            prefix: alertConfiguration.prefix,
            mode: 'edit',
            description: alertConfiguration.description,
            deviceIds: alertConfiguration.rocAlertDevices.map((device) => device.device.id),
            deviceOperationResultIds: alertConfiguration.rocAlertDeviceOperationResults.map(
              (result) => result.deviceOperationResult.id
            ),
            deviceOperationResultSelectionType: alertConfiguration.deviceOperationResultSelectionType as SelectionType,
            deviceSelectionType: alertConfiguration.deviceSelectionType as SelectionType,
            enabled: alertConfiguration.enabled,
            enableStatusAlerts: alertConfiguration.enableStatusAlerts,
            enableStatusAlertRecovery: alertConfiguration.enableStatusAlertRecovery,
            enableAcdCycleAlerts: alertConfiguration.enableAcdcycleAlerts,
            name: alertConfiguration.name,
            rocAlertChannelId: alertConfiguration.rocAlertChannel!.id,
            rocAlertChannelCode: alertConfiguration.rocAlertChannel!.code as RocAlertChannelType,
            alertTeamsUrl: alertConfiguration.rocAlertChannelParameterValues.find(
              (item) => item.rocAlertChannelParameter.name === 'webhook_url'
            )?.value,
            alertEmails: alertConfiguration.rocAlertChannelParameterValues.find(
              (item) => item.rocAlertChannelParameter.name === 'email'
            )?.value,
            statusAlertTypes: alertConfiguration.statusAlertTypes as RocAlertStatusType[]
          }}
        />
      ) : (
        <div className="alert-configuration-drawer__view" data-testid="alert-configuration-drawer-data-view">
          <InformationBlock
            label={t('companyAdminDetailsPage.alertConfigurationDrawer.name')}
            data-testid="alert-configuration-drawer-name"
            value={alertConfiguration.name}
          />
          <InformationBlock
            label={t('companyAdminDetailsPage.alertConfigurationDrawer.prefix')}
            data-testid="alert-configuration-drawer-prefix"
            value={getNonNullishDisplayValue(alertConfiguration.prefix, true)}
          />
          <InformationBlock
            label={t('companyAdminDetailsPage.alertConfigurationDrawer.description')}
            data-testid="alert-configuration-drawer-description"
            value={alertConfiguration.description}
          />
          <InformationBlock
            label={t('companyAdminDetailsPage.alertConfigurationDrawer.alertStatus')}
            data-testid="alert-configuration-drawer-alert-status"
            value={contextualizeBoolean(
              t('companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.enabled'),
              t('companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.disabled'),
              alertConfiguration.enabled
            )}
          />
          <InformationBlock
            label={t('companyAdminDetailsPage.alertConfigurationDrawer.rocs')}
            data-testid="alert-configuration-drawer-rocs-selection-type"
            value={
              <div className="alert-configuration-drawer__rocs-selection-type">
                {capitalize(startCase(alertConfiguration.deviceSelectionType))}
                {alertConfiguration.deviceSelectionType !== 'ALL' &&
                  alertConfiguration.rocAlertDevices.map((item) => (
                    <span className="alert-configuration-drawer__roc-serial-number" key={item.id}>
                      {item.device
                        ? getNonNullishDisplayValue(item.device.serialNumber)
                        : t('companyAdminDetailsPage.alertConfigurationDrawer.deviceUnavailablePlaceholder')}
                    </span>
                  ))}
              </div>
            }
          />
          {alertConfiguration.rocAlertChannel && (
            <InformationBlock
              label={t('companyAdminDetailsPage.alertConfigurationDrawer.notificationChannel')}
              data-testid="alert-configuration-drawer-notification-channel"
              value={capitalize(startCase(alertConfiguration.rocAlertChannel.code))}
            />
          )}
          {alertConfiguration.rocAlertChannel?.code === 'TEAMS' && (
            <InformationBlock
              label={t('companyAdminDetailsPage.alertConfigurationDrawer.teamsUrl')}
              data-testid="alert-configuration-drawer-notification-channel-teams-urls"
              value={
                <div className="alert-configuration-drawer__notification-channel-urls">
                  {alertConfiguration.rocAlertChannelParameterValues.map((value) => (
                    <span
                      className="alert-configuration-drawer__notification-channel-teams-url"
                      key={value.id}
                      title={value.value}
                    >
                      {value.value}
                    </span>
                  ))}
                </div>
              }
            />
          )}
          {alertConfiguration.rocAlertChannel?.code === 'EMAIL' && (
            <InformationBlock
              label={t('companyAdminDetailsPage.alertConfigurationDrawer.email')}
              data-testid="alert-configuration-drawer-notification-channel-emails"
              value={
                <div className="alert-configuration-drawer__notification-channel-emails">
                  {alertConfiguration.rocAlertChannelParameterValues.map((value) => (
                    <span
                      className="alert-configuration-drawer__notification-channel-email"
                      key={value.id}
                      title={value.value}
                    >
                      {value.value}
                    </span>
                  ))}
                </div>
              }
            />
          )}
          <div className="alert-configuration-drawer__status-alerts">
            <div className="alert-configuration-drawer__subtitle">
              <span className="alert-configuration-drawer__alerts-title">
                {t('companyAdminDetailsPage.alertConfigurationDrawer.rocStatusAlerts')}
              </span>
              <span>
                {contextualizeBoolean(
                  t('companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.included'),
                  t('companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.excluded'),
                  alertConfiguration.enableStatusAlerts
                )}
              </span>
            </div>
            <div className="alert-configuration-drawer__alerts-information">
              <InformationBlock
                label={t('companyAdminDetailsPage.alertConfigurationDrawer.statusAlertTypes')}
                data-testid="alert-configuration-drawer-status-alert-types"
                value={
                  <div className="alert-configuration-drawer__status-alert-types">
                    {alertConfiguration.statusAlertTypes
                      ? alertConfiguration.statusAlertTypes.map((item) => (
                          <span className="alert-configuration-drawer__status-alert-type" key={item}>
                            {capitalize(startCase(item))}
                          </span>
                        ))
                      : getNonNullishDisplayValue(undefined)}
                  </div>
                }
              />
              <InformationBlock
                label={t('companyAdminDetailsPage.alertConfigurationDrawer.includeRecoveryAlerts')}
                data-testid="alert-configuration-drawer-include-recovery-alerts"
                value={contextualizeBoolean(
                  t('companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.yes'),
                  t('companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.no'),
                  alertConfiguration.enableStatusAlertRecovery
                )}
              />
            </div>
          </div>
          <div className="alert-configuration-drawer__acd-cycles-alerts">
            <div className="alert-configuration-drawer__subtitle">
              <span className="alert-configuration-drawer__alerts-title">
                {t('companyAdminDetailsPage.alertConfigurationDrawer.acdCycleAlerts')}
              </span>
              <span>
                {contextualizeBoolean(
                  t('companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.included'),
                  t('companyAdminDetailsPage.alertConfigurationDrawer.booleanContextualization.excluded'),
                  alertConfiguration.enableAcdcycleAlerts
                )}
              </span>
            </div>
            <div className="alert-configuration-drawer__alerts-information">
              <InformationBlock
                label={t('companyAdminDetailsPage.alertConfigurationDrawer.results')}
                data-testid="alert-configuration-drawer-acd-cycle-alerts-results"
                value={
                  <div className="alert-configuration-drawer__acd-cycle-alerts-results">
                    <span>
                      {t('companyAdminDetailsPage.alertConfigurationDrawer.acdCycleAlertsSelectionType', {
                        selectionType: capitalize(startCase(alertConfiguration.deviceOperationResultSelectionType))
                      })}
                    </span>
                    {alertConfiguration.rocAlertDeviceOperationResults.map((item) => (
                      <span key={item.id}>{item.deviceOperationResult.name}</span>
                    ))}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      )}
    </RSDrawer>
  );
};
