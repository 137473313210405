import { DefaultValues } from 'react-hook-form';
import { z } from 'zod';

import { RocAlertStatusType, SelectionType } from '../../../../../__generated__/graphql';
import i18n from '../../../../../i18n';
import { RocAlertChannelType } from '../../../../../types/roc-alert-configuration';

export const alertConfigurationSchema = z
  .object({
    mode: z.enum(['create', 'edit']),
    companyId: z.string().uuid().nullish(),
    id: z.string().uuid().nullish(),
    description: z.string().nullish(),
    deviceIds: z.string().uuid().array(),
    deviceOperationResultIds: z.string().uuid().array(),
    // Even if device alerts or ACDCycle alerts are disabled, the types are still required. Default to "ALL"
    deviceOperationResultSelectionType: z.nativeEnum(SelectionType),
    deviceSelectionType: z.nativeEnum(SelectionType),
    enableAcdCycleAlerts: z.boolean(),
    enableStatusAlertRecovery: z.boolean(),
    enableStatusAlerts: z.boolean(),
    enabled: z.boolean(),
    name: z
      .string()
      .trim()
      .min(1, { message: i18n.t('forms.inputValidation.emptyInput') }),
    prefix: z.string().optional(),
    rocAlertChannelId: z.string().uuid(),
    // This field is not used for submission; only for validation post-processing
    rocAlertChannelCode: z.nativeEnum(RocAlertChannelType).nullish(),
    alertTeamsUrl: z
      .string()
      .url({ message: i18n.t('forms.inputValidation.invalidUrl') })
      .nullish(),
    alertEmails: z.union([
      z.string().refine(
        (val) => {
          const emails = val.split(',').map((email) => email.trim());
          return emails.every((email) => z.string().email().safeParse(email).success);
        },
        { message: i18n.t('companyAdminDetailsPage.alertConfigurationDrawer.validations.email') }
      ),
      z.null(),
      z.undefined()
    ]),
    statusAlertTypes: z.nativeEnum(RocAlertStatusType).array()
  })
  .refine(
    (data) => {
      if (data.mode === 'edit' && !data.id) {
        return false;
      }
      return true;
    },
    {
      message: i18n.t('companyAdminDetailsPage.alertConfigurationDrawer.idRequired'),
      path: ['id']
    }
  )
  .refine(
    (data) => {
      if (data.mode === 'create' && !data.companyId) {
        return false;
      }
      return true;
    },
    {
      message: i18n.t('companyAdminDetailsPage.alertConfigurationDrawer.companyIdRequired'),
      path: ['companyId']
    }
  )
  .refine(
    (data) => {
      // If alert channel ID exists and alert channel code is Teams, alertTeamsUrl must be provided
      if (data.rocAlertChannelId && data.rocAlertChannelCode === RocAlertChannelType.Teams) {
        return Boolean(data.alertTeamsUrl);
      }
      return true;
    },
    {
      message: i18n.t('companyAdminDetailsPage.alertConfigurationDrawer.validations.alertTeamsUrl'),
      path: ['alertTeamsUrl']
    }
  )
  .refine(
    (data) => {
      if (data.rocAlertChannelId && data.rocAlertChannelCode === RocAlertChannelType.Email) {
        return Boolean(data.alertEmails);
      }
      return true;
    },
    {
      message: i18n.t('companyAdminDetailsPage.alertConfigurationDrawer.validations.alertEmails'),
      path: ['alertEmails']
    }
  );

export type AlertConfigurationRequest = z.infer<typeof alertConfigurationSchema>;

export const generateAlertConfigurationCreateDefaultValues = (
  companyId: string
): DefaultValues<AlertConfigurationRequest> => {
  return {
    companyId,
    mode: 'create',
    enabled: true,
    enableStatusAlerts: true,
    enableStatusAlertRecovery: false,
    enableAcdCycleAlerts: true,
    deviceSelectionType: SelectionType.All,
    deviceOperationResultSelectionType: SelectionType.All,
    statusAlertTypes: [
      RocAlertStatusType.Online,
      RocAlertStatusType.SafetyTriggered,
      RocAlertStatusType.DeviceState,
      RocAlertStatusType.StorageUsage,
      RocAlertStatusType.MemoryUsage,
      RocAlertStatusType.QueueLength,
      RocAlertStatusType.ZOffset
    ],
    deviceIds: [],
    deviceOperationResultIds: []
  };
};
