import { gql } from '../../../../__generated__/gql';

export const MUTATION_CREATE_ROC_ALERT_CONFIGURATION = gql(`
  mutation createRocAlertConfiguration(
    $name: String!
    $prefix: String
    $companyId: uuid!
    $enabled: Boolean!
    $description: String
    $deviceSelectionType: SelectionType!
    $enableStatusAlerts: Boolean!
    $statusAlertTypes: [RocAlertStatusType!]
    $enableStatusAlertRecovery: Boolean!
    $enableAcdCycleAlerts: Boolean!
    $deviceOperationResultSelectionType: SelectionType!
    $rocAlertChannelId: uuid!
    $rocAlertChannelParameterValues: [RocAlertChannelParameterValueInput]
    $deviceIds: [uuid!]
    $deviceOperationResultIds: [uuid!]
  ) {
    createRocAlertConfiguration(
      name: $name
      prefix: $prefix
      companyId: $companyId
      enabled: $enabled
      description: $description
      deviceSelectionType: $deviceSelectionType
      enableStatusAlerts: $enableStatusAlerts
      statusAlertTypes: $statusAlertTypes
      enableStatusAlertRecovery: $enableStatusAlertRecovery
      enableAcdCycleAlerts: $enableAcdCycleAlerts
      deviceOperationResultSelectionType: $deviceOperationResultSelectionType
      rocAlertChannelId: $rocAlertChannelId
      rocAlertChannelParameterValues: $rocAlertChannelParameterValues
      deviceIds: $deviceIds
      deviceOperationResultIds: $deviceOperationResultIds
    ) {
      id
      rocAlertConfiguration {
        id
        prefix
        name
        description
        deviceSelectionType
        enabled
        enableStatusAlerts
        enableStatusAlertRecovery
        enableAcdcycleAlerts
        statusAlertTypes
        deviceOperationResultSelectionType
        rocAlertDeviceOperationResults {
          id
          deviceOperationResult {
            id
            code
            name
          }
        }
        rocAlertChannel {
          id
          code
        }
        rocAlertChannelParameterValues {
          id
          value
          rocAlertChannelParameter {
            id
            label
            name
            order
            type
          }
        }
        rocAlertDevices(orderBy: { device: { serialNumber: ASC } }) {
          id
          device {
            id
            serialNumber
          }
        }
      }
    }
  }
`);
