import CodeIcon from '@mui/icons-material/Code';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import VoiceChatOutlinedIcon from '@mui/icons-material/VoiceChatOutlined';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { BarChart, LineChart, PieChart, ScatterChart } from '@mui/x-charts';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import classNames from 'classnames';
import { toString, toUpper } from 'lodash';
import { DateTime } from 'luxon';
import { JSX, useContext, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { useTranslation } from 'react-i18next';

import { dummyMovies } from '../../../__mocks__/dummy-data/movies';
import ArrowLeftIcon from '../../../assets/icons/arrow-left.svg?react';
import CalendarIcon from '../../../assets/icons/calendar.svg?react';
import CheckmarkIcon from '../../../assets/icons/check-mark.svg?react';
import ExternalLinkIcon from '../../../assets/icons/external-link.svg?react';
import ImageIcon from '../../../assets/icons/image.svg?react';
import LightIcon from '../../../assets/icons/light.svg?react';
import MetadataIcon from '../../../assets/icons/metadata.svg?react';
import { RSAutocompleteValue } from '../../../types/rs-autocomplete';
import { browserTimezone } from '../../../utilities/get-browser-timezone/get-browser-timezone';
import { CodeViewer } from '../../4-features/code-viewer/code-viewer';
import { ModalDrawerFooter } from '../../4-features/modal-drawer-footer/modal-drawer-footer';
import { ModalDrawerHeader } from '../../4-features/modal-drawer-header/modal-drawer-header';
import { PerformanceMetricBlock } from '../../4-features/performance-metric-block/performance-metric-block';
import { UploadStatusChip } from '../../4-features/upload-status-chip/upload-status-chip';
import { CountBlock } from '../../5-elements/count-block/count-block';
import { DisplayTable } from '../../5-elements/display-table/display-table';
import { ExternalLink } from '../../5-elements/external-link/external-link';
import { IconWithDescription } from '../../5-elements/icon-with-description/icon-with-description';
import { InformationBlock } from '../../5-elements/information-block/information-block';
import { InternalLink } from '../../5-elements/internal-link/internal-link';
import { RSActionButton } from '../../5-elements/rs-action-button/rs-action-button';
import { RSAutocomplete } from '../../5-elements/rs-autocomplete/rs-autocomplete';
import { RSButton } from '../../5-elements/rs-button/rs-button';
import { RSCheckbox } from '../../5-elements/rs-checkbox-group/rs-checkbox';
import { RSCheckboxGroup } from '../../5-elements/rs-checkbox-group/rs-checkbox-group';
import { RSChip } from '../../5-elements/rs-chip/rs-chip';
import { RSRadio } from '../../5-elements/rs-radio-group/rs-radio';
import { RSRadioGroup } from '../../5-elements/rs-radio-group/rs-radio-group';
import { RSSelect } from '../../5-elements/rs-select/rs-select';
import { RSSwitch } from '../../5-elements/rs-switch/rs-switch';
import { RSTextInput } from '../../5-elements/rs-text-input/rs-text-input';
import { UserTimezoneContext } from '../../contexts/user-timezone-context';

export const TestPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { userTimezone } = useContext(UserTimezoneContext);
  const [lineChartInterpolationMethod, setLineChartInterpolationMethod] = useState<'monotoneX' | 'linear'>('monotoneX');
  const [dateTimeValue, setDateTimeValue] = useState<DateTime | null | undefined>(DateTime.now().startOf('minute'));
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>('Version 3');

  const testCustomMenuOption = (option: RSAutocompleteValue) => {
    return (
      <span className="test-page__dummy-menu-option">
        <LiveTvOutlinedIcon />
        &nbsp;{option}
      </span>
    );
  };
  const testCustomTagOption = (option: RSAutocompleteValue) => {
    return (
      <span className="test-page__dummy-tag-option">
        <VoiceChatOutlinedIcon fontSize="small" />
        &nbsp;{toUpper(toString(option))}
      </span>
    );
  };

  const getVersion = (dateTime: DateTime | null): string | undefined => {
    if (!dateTime) {
      return undefined;
    }
    if (dateTime.toUnixInteger() >= 1735686000 && dateTime.toUnixInteger() < 1738364400) {
      return 'Version 1';
    }
    if (dateTime.toUnixInteger() >= 1738364400 && dateTime.toUnixInteger() < 1740783600) {
      return 'Version 2';
    }
    if (dateTime.toUnixInteger() > 1740783600) {
      return 'Version 3';
    }
    return undefined;
  };

  const getSoftwareVersionClassNames = (version: string): string => {
    return classNames('test-page__timeline-main-title', {
      'test-page__timeline-main-title--highlighted': version === selectedVersion
    });
  };
  const getSoftwareVersionSubTitleClassNames = (version: string): string => {
    return classNames('test-page__timeline-sub-title', {
      'test-page__timeline-sub-title--highlighted': version === selectedVersion
    });
  };

  // Code Diff viewer
  const oldCode = `
  {
  "socket_in_base": {
    "socket_pose": {
      "position": {
        "x": 1.14929304713332,
        "y": 0.08138222093335662,
        "z": 0.6203037288759404
      },
      "orientation": {
        "x": -0.07394724533627399,
        "y": -0.02256982506369489,
        "z": -0.08133193340371031
      }
    },
    "estimation_type": 2
  }`;

  const newCode = `
  {
  "socket_in_base": {
    "socket_pose": {
      "position": {
        "x": 4.6347475755757575,
        "y": 0.08138222093335662,
        "z": 0.6203037288759404
      },
      "orientation": {
        "x": -0.07394724533627399,
        "y": -0.03363463457457557,
        "z": -0.08133193340371031
      }
    },
    "estimation_type": 3
  }`;

  // Chars
  const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
  const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
  const lineChartXLabels = [
    new Date('2025-01-01'),
    new Date('2025-01-02'),
    new Date('2025-01-03'),
    new Date('2025-01-04'),
    new Date('2025-01-05'),
    new Date('2025-01-06'),
    new Date('2025-01-07')
  ];
  const scatterChartData = [
    { id: 'data-0', x1: 329.39, x2: 391.29, y1: 443.28, y2: 153.9 },
    { id: 'data-1', x1: 96.94, x2: 139.6, y1: 110.5, y2: 217.8 },
    { id: 'data-2', x1: 336.35, x2: 282.34, y1: 175.23, y2: 286.32 },
    { id: 'data-3', x1: 159.44, x2: 384.85, y1: 195.97, y2: 325.12 },
    { id: 'data-4', x1: 188.86, x2: 182.27, y1: 351.77, y2: 144.58 },
    { id: 'data-5', x1: 143.86, x2: 360.22, y1: 43.253, y2: 146.51 },
    { id: 'data-6', x1: 202.02, x2: 209.5, y1: 376.34, y2: 309.69 },
    { id: 'data-7', x1: 384.41, x2: 258.93, y1: 31.514, y2: 236.38 },
    { id: 'data-8', x1: 256.76, x2: 70.571, y1: 231.31, y2: 440.72 },
    { id: 'data-9', x1: 143.79, x2: 419.02, y1: 108.04, y2: 20.29 },
    { id: 'data-10', x1: 103.48, x2: 15.886, y1: 321.77, y2: 484.17 },
    { id: 'data-11', x1: 272.39, x2: 189.03, y1: 120.18, y2: 54.962 },
    { id: 'data-12', x1: 23.57, x2: 456.4, y1: 366.2, y2: 418.5 },
    { id: 'data-13', x1: 219.73, x2: 235.96, y1: 451.45, y2: 181.32 },
    { id: 'data-14', x1: 54.99, x2: 434.5, y1: 294.8, y2: 440.9 },
    { id: 'data-15', x1: 134.13, x2: 383.8, y1: 121.83, y2: 273.52 },
    { id: 'data-16', x1: 12.7, x2: 270.8, y1: 287.7, y2: 346.7 },
    { id: 'data-17', x1: 176.51, x2: 119.17, y1: 134.06, y2: 74.528 },
    { id: 'data-18', x1: 65.05, x2: 78.93, y1: 104.5, y2: 150.9 },
    { id: 'data-19', x1: 162.25, x2: 63.707, y1: 413.07, y2: 26.483 },
    { id: 'data-20', x1: 68.88, x2: 150.8, y1: 74.68, y2: 333.2 },
    { id: 'data-21', x1: 95.29, x2: 329.1, y1: 360.6, y2: 422.0 },
    { id: 'data-22', x1: 390.62, x2: 10.01, y1: 330.72, y2: 488.06 }
  ];

  return (
    <div className="test-page">
      <h1>Components Demo 🤖</h1>
      <div className="test-page__code-diff-viewer">
        <h2 className="test-page__division-title">Code Diff viewer</h2>
        <ReactDiffViewer
          oldValue={oldCode}
          newValue={newCode}
          splitView={false}
          showDiffOnly={false}
          useDarkTheme={true}
        />
        <br />
        <CodeViewer data={newCode} />
      </div>
      <div className="test-page__timeline">
        <h2 className="test-page__division-title">Timeline</h2>
        <div className="test-page__timeline-component">
          <Timeline className="test-page__timeline">
            <TimelineItem className="test-page__timeline-item">
              <TimelineSeparator>
                <TimelineDot className="test-page__timeline-dot">
                  <CodeIcon fontSize="large" />
                </TimelineDot>
                <TimelineConnector className="test-page__timeline-connector" />
              </TimelineSeparator>
              <TimelineContent className="test-page__timeline-content">
                <span className={getSoftwareVersionClassNames('Version 1')}>Version 1</span>
                <span className={getSoftwareVersionSubTitleClassNames('Version 1')}>
                  {`${DateTime.fromISO('2024-12-31T23:00:00.000Z').setZone(userTimezone).toLocaleString(DateTime.DATETIME_FULL)}`}
                </span>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem className="test-page__timeline-item">
              <TimelineSeparator>
                <TimelineDot className="test-page__timeline-dot">
                  <CodeIcon fontSize="large" />
                </TimelineDot>
                <TimelineConnector className="test-page__timeline-connector" />
              </TimelineSeparator>
              <TimelineContent className="test-page__timeline-content">
                <span className={getSoftwareVersionClassNames('Version 2')}>Version 2</span>
                <span className={getSoftwareVersionSubTitleClassNames('Version 2')}>
                  {`${DateTime.fromISO('2025-01-31T23:00:00.000Z').setZone(userTimezone).toLocaleString(DateTime.DATETIME_FULL)}`}
                </span>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem className="test-page__timeline-item">
              <TimelineSeparator>
                <TimelineDot className="test-page__timeline-dot">
                  <CodeIcon fontSize="large" />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent className="test-page__timeline-content">
                <span className={getSoftwareVersionClassNames('Version 3')}>Version 3</span>
                <span className={getSoftwareVersionSubTitleClassNames('Version 3')}>
                  {`${DateTime.fromISO('2025-02-28T23:00:00.000Z').setZone(userTimezone).toLocaleString(DateTime.DATETIME_FULL)}`}
                </span>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div>
      </div>
      <div className="test-page__date-time-picker">
        <h2 className="test-page__division-title">Date time picker</h2>
        <p>{`Selected date time: ${dateTimeValue?.setZone(userTimezone).toLocaleString(DateTime.DATETIME_FULL)}`}</p>
        <p>Try to play the datetime picker to select a software version (note: time based on CET):</p>
        <ul>
          <li>Select a datetime before 1 Jan 2025: Nothing will be selected</li>
          <li>Select a datetime between 1 Jan 2025 and 1 Feb 2025: Version 1</li>
          <li>Select a datetime between 1 Feb 2025 and 1 Mar 2025: Version 2</li>
          <li>Select a datetime after 1 Mar 2025: Version 3</li>
        </ul>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-GB">
          <DateTimePicker
            className="test-date-time-picker"
            slotProps={{
              desktopPaper: { className: 'test-date-time-picker__calendar' },
              calendarHeader: { className: 'test-date-time-picker__calendar-header' },
              actionBar: { actions: ['today'] }
            }}
            slots={{ openPickerIcon: CalendarIcon }}
            value={dateTimeValue}
            onChange={(newValue) => {
              setDateTimeValue(newValue?.startOf('minute'));
              const softwareVersion = getVersion(newValue);
              setSelectedVersion(softwareVersion);
            }}
            displayWeekNumber={true}
            dayOfWeekFormatter={(weekday) => `${weekday.toFormat('ccc')}`}
            disableFuture={true}
            timezone={userTimezone || browserTimezone}
            format="dd MMM yyyy, HH:mm"
            // See more about the translation from https://mui.com/x/react-date-pickers/localization/
            localeText={{ todayButtonLabel: 'Now' }}
          />
        </LocalizationProvider>
      </div>
      <br />
      <div className="test-page__charts">
        <h2 className="test-page__division-title">Charts test</h2>
        <div className="test-page__bar-chart">
          <BarChart
            series={[
              { data: [35, 44, 24, 34] },
              { data: [51, 6, 49, 30] },
              { data: [15, 25, 30, 50] },
              { data: [60, 50, 15, 25] }
            ]}
            height={290}
            xAxis={[{ data: ['Q1', 'Q2', 'Q3', 'Q4'], scaleType: 'band' }]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        </div>
        <br />
        <div className="test-page__line-chart">
          <RSButton
            variant="contained"
            onClick={() =>
              setLineChartInterpolationMethod((prevValue) => (prevValue === 'linear' ? 'monotoneX' : 'linear'))
            }
          >{`Line chart: Switch to ${lineChartInterpolationMethod === 'linear' ? 'monotoneX' : 'linear'}`}</RSButton>
          <LineChart
            height={300}
            series={[
              { data: pData, label: 'ROC00002001', curve: lineChartInterpolationMethod },
              { data: uData, label: 'ROC00003001', curve: lineChartInterpolationMethod }
            ]}
            xAxis={[
              {
                scaleType: 'time',
                data: lineChartXLabels,
                tickInterval: lineChartXLabels,
                valueFormatter: (date) => DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT)
              }
            ]}
            grid={{ vertical: true, horizontal: true }}
            skipAnimation={true}
          />
        </div>
        <br />
        <div>
          <PieChart
            series={[
              {
                data: [
                  { id: 0, value: 10, label: 'series A' },
                  { id: 1, value: 15, label: 'series B' },
                  { id: 2, value: 20, label: 'series C' }
                ]
              }
            ]}
            width={400}
            height={200}
          />
        </div>
        <br />
        <div>
          <ScatterChart
            width={600}
            height={300}
            series={[
              { label: 'Series A', data: scatterChartData.map((v) => ({ x: v.x1, y: v.y1, id: v.id })) },
              { label: 'Series B', data: scatterChartData.map((v) => ({ x: v.x1, y: v.y2, id: v.id })) }
            ]}
          />
        </div>
      </div>
      <div className="test-page__tooltip-test">
        <h2 className="test-page__division-title">tooltip test</h2>
        <RSTextInput inputLabel="hmmm" inputLabelTooltip="this is some text" required={true} />
        <RSSelect inputLabel="select" required={true} inputLabelTooltip="🐻‍❄️ polar bear" defaultValue="" />
        <RSAutocomplete
          customMenuOption={testCustomMenuOption}
          options={dummyMovies}
          inputLabel="Some test label"
          inputLabelTooltip="🦫 We are going to build a dam"
        />
        <RSSwitch
          falseValueLabel="False"
          trueValueLabel="True"
          formLabel="Is seal and sea lion the same animal?"
          formLabelTooltip="🦭 A seal is not a sea lion!!"
        />
        <RSCheckboxGroup formLabel="Checkbox test" formLabelTooltip="🐹🧻 Niet hamsteren">
          <RSCheckbox defaultChecked color="primary" label="Primary color" />
          <RSCheckbox defaultChecked color="error" label="Error color" />
          <RSCheckbox defaultChecked color="warning" label="Warning color" />
          <RSCheckbox defaultChecked color="success" label="Success color" />
          <RSCheckbox defaultChecked color="primary" label="Disabled" disabled />
        </RSCheckboxGroup>
        <RSRadioGroup
          formLabel="Radio group test"
          formLabelTooltip="🐨 I need 22 hours of sleep every day, do not disturb"
        >
          <RSRadio label="bar" value="bar" />
          <RSRadio label="foo" value="foo" />
        </RSRadioGroup>
      </div>
      <br />
      <div>
        <h2 className="test-page__division-title">Display table</h2>
        <DisplayTable
          columnHeader={{
            headerCells: [
              { children: 'Serial number', key: 'serial-number' },
              { children: 'ROC type', key: 'device-type' },
              { children: 'Connector holder', key: 'connector-holder' }
            ]
          }}
          rows={[
            {
              key: 'first',
              cells: [
                { children: 'hmmm', key: 'hmmm' },
                { children: 'blabla', key: 'blabla' },
                { children: 'asdf', key: 'asdf' }
              ]
            },
            {
              key: 'second',
              cells: [
                { children: 'hmmm', key: '123' },
                { children: 'blabla', key: '12312' },
                { children: 'asdf', key: '12312312' }
              ]
            }
          ]}
        />
      </div>
      <br />
      <div>
        <h2 className="test-page__division-title">Performance metrics</h2>
        <p>
          The performance metrics is a <b>presentation</b> component, meaning that it displays the value it receives as
          it is. The decimal places handling is done by <b>independent utility functions</b>. In the example below, we
          show different combinations of both the presentation component and the utility function.
        </p>
        <br />
        <div className="test-page__performance-metrics">
          <PerformanceMetricBlock
            label="Default behaviour: decimal places 1 (the actual value is 2.7364 / -1.2345)"
            metric={{ value: 2.7364, displayUnit: '%' }}
            metricChange={{ value: -1.2345, displayUnit: '%' }}
          />
          <PerformanceMetricBlock
            label="Decimal places 3 (the actual value is 2.7364 / -1.2345)"
            metric={{ value: 2.7364, displayUnit: '%', decimalPlaces: 3 }}
            metricChange={{ value: -1.2345, displayUnit: '%', decimalPlaces: 3 }}
          />
          <PerformanceMetricBlock
            label="Metric: show 0 for 0; metric change: show change value 0"
            metric={{ value: 0, displayUnit: '%' }}
            metricChange={{ value: 0, displayUnit: '%' }}
          />
          <PerformanceMetricBlock
            label="Decimal place: 0, change value is null"
            metric={{ value: 3, displayUnit: ' capybaras', decimalPlaces: 0 }}
            metricChange={{ value: null }}
          />
        </div>
      </div>
      <div>
        <h2 className="test-page__division-title">Buttons</h2>
        <div className="test-page__buttons">
          <RSButton variant="contained">Default</RSButton>
          <RSButton variant="text" startIcon={<ExternalLinkIcon />}>
            Cancel
          </RSButton>
          <RSButton variant="contained" color="success">
            Confirm
          </RSButton>
          <RSButton variant="contained" color="warning">
            Warning
          </RSButton>
          <RSButton variant="contained" color="error">
            Error
          </RSButton>
        </div>
      </div>
      <div>
        <h2 className="test-page__division-title">Image overlay - header and bottom bar</h2>
        <div className="test-page__modal-header-footer-bar">
          <ModalDrawerHeader
            title="INITIAL-0-RAW"
            actions={
              <div>
                <RSActionButton
                  text={t('operationsPage.operationDetails.imageModal.actions.image')}
                  startIcon={<ImageIcon className="rs-action-button__start-icon" />}
                  iconContainerClassName="rs-action-button__icon-container__image"
                  data-testid="operation-details-image-modal-image-button"
                  onClick={() => alert('image action')}
                />
                <RSActionButton
                  text={t('operationsPage.operationDetails.imageModal.actions.metaData')}
                  startIcon={<MetadataIcon className="rs-action-button__start-icon" />}
                  iconContainerClassName="rs-action-button__icon-container__metadata"
                  data-testid="operation-details-image-modal-metadata-button"
                  onClick={() => alert('metadata action')}
                />
              </div>
            }
            handleClose={() => {
              alert('Close modal');
            }}
          />
          <div className="test-page__modal-header-footer-bar__placeholder"></div>
          <ModalDrawerFooter
            informationBlocks={
              <>
                <InformationBlock
                  label={t('operationsPage.operationDetails.imageModal.result')}
                  // @ts-expect-error - For demo purposes
                  value={<UploadStatusChip uploadStatus="UPLOAD_DONE" />}
                />
                <InformationBlock label={t('operationsPage.operationDetails.imageModal.size')} value="Catsum Ipsum" />
                <InformationBlock
                  label={t('operationsPage.operationDetails.imageModal.fileName')}
                  value="Attack feet behind the couch"
                />
                <InformationBlock
                  label={t('operationsPage.operationDetails.imageModal.createdAt', {
                    timezone: 'CAT+2'
                  })}
                  value="Destroy couch flop over"
                />
              </>
            }
            actions={
              <>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cdn.pixabay.com/photo/2023/07/04/04/44/ai-generated-8105386_960_720.jpg"
                  data-testid="operation-image-modal-download-link"
                >
                  <RSButton variant="contained" data-testid="operation-image-modal-download-button">
                    {t('operationsPage.operationDetails.imageModal.downloadImage')}
                  </RSButton>
                </a>
              </>
            }
          />
        </div>
      </div>
      <div>
        <h2 className="test-page__division-title">Customer Details Test Page</h2>
        <ExternalLink url="/portal/customers/details-test">Go to the Test Page</ExternalLink>
      </div>
      <div>
        <h2 className="test-page__division-title">Internal Link</h2>
        <InternalLink to="/portal" icon={<ArrowLeftIcon />} text="Go to the Portal" />
      </div>
      <div>
        <h2 className="test-page__division-title">Count Block</h2>
        <CountBlock title="ROCs" count={46} />
      </div>
      <div>
        <h2 className="test-page__division-title">Header Styling</h2>
        <h1>Header H1</h1>
        <br />
        <br />
        <h2>Header H2</h2>
        <br />
        <br />
        <h3>Header H3</h3>
        <br />
        <br />
      </div>
      <div className="test-page__autocomplete">
        <h2 className="test-page__division-title">Autocomplete</h2>
        <p>This div has a white background: the autocomplete component is transparent</p>
        <div className="test-page__autocomplete-container">
          <RSAutocomplete options={dummyMovies} inputLabel="One test" />
        </div>
        <br />
        <p>This one below uses customised menu option rendering:</p>
        <div className="test-page__autocomplete-container">
          <RSAutocomplete customMenuOption={testCustomMenuOption} options={dummyMovies} inputLabel="Some test label" />
        </div>
        <br />
        <p>This one below uses customised menu option and customised tag option rendering:</p>
        <div className="test-page__autocomplete-container">
          <RSAutocomplete
            customMenuOption={testCustomMenuOption}
            customTagOption={testCustomTagOption}
            options={dummyMovies}
            inputLabel="Another test"
          />
        </div>
        <br />
        <p>
          No matter how the tags or menu options get rendered, the values are always the same: that is the `options`.
        </p>
      </div>
      <div>
        <h2 className="test-page__division-title">Information Blocks</h2>
        <div className="test-page__information-blocks">
          <InformationBlock label="Hoi" value="🐻 ik ben een beer" subValue="Wij willen meer beertjes!" />
          <InformationBlock
            label="I have a chip"
            value={<RSChip color="success" label="I have success" icon={<CheckmarkIcon />} />}
            subValue="Some strange remark"
          />
          <InformationBlock
            label="In case you want some cute animals"
            value={
              <ExternalLink url="https://izushaboten.com/kapiroten-en/">Capybaras enjoying hot spring</ExternalLink>
            }
          />
          <InformationBlock
            label="Green Light 🚥"
            value={<IconWithDescription icon={<LightIcon />} color="success" description="You can go 🚗" />}
          />
        </div>
      </div>
      <div>
        <h2 className="test-page__division-title">Sentry test</h2>
        <div className="test-page__sentry-test">
          <RSButton
            variant="contained"
            color="error"
            onClick={() => {
              throw new Error('I am an error');
            }}
          >
            Trigger error
          </RSButton>
        </div>
      </div>
    </div>
  );
};
