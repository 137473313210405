import { gql } from '../../../../__generated__/gql';

export const GET_ROC_ALERT_CONFIGURATION = gql(`
  query getRocAlertConfiguration($id: uuid!) {
    rocAlertConfigurationByPk(id: $id) {
      id
      prefix
      name
      description
      deviceSelectionType
      enabled
      enableStatusAlerts
      enableStatusAlertRecovery
      enableAcdcycleAlerts
      statusAlertTypes
      deviceOperationResultSelectionType
      rocAlertDeviceOperationResults {
        id
        deviceOperationResult {
          id
          code
          name
        }
      }
      rocAlertChannel {
        id
        code
      }
      rocAlertChannelParameterValues {
        id
        value
        rocAlertChannelParameter {
          id
          label
          name
          order
          type
        }
      }
      rocAlertDevices(orderBy: { device: { serialNumber: ASC } }) {
        id
        device {
          id
          serialNumber
        }
      }
    }
  }
`);
